<template>
  <MicroApp name="gauge" :entry="url"/>
</template>

<script>
import MicroApp from './MicroApp'

export default {
  data () {
    return {
      url: GLOBAL_CONFIG.gauge_h5_url
    }
  },
  components: {
    MicroApp
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
