<template>
  <div ref="microBox" class="g_micro_box"></div>
</template>

<script>
import { loadMicroApp } from 'qiankun'

export default {
  props: {
    name: {
      type: String,
      default: ''
    },
    entry: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      microApp: null
    }
  },
  mounted() {
    const name = this.name;
    const entry = this.entry;
    this.microApp = loadMicroApp({
      name,
      entry,
      container: this.$refs.microBox,
    })
  },
  destroyed() {
    this.microApp.unmount()
  },
  updated() {
    this.microApp.update({
      name: this.name,
      entry: this.entry,
    })
  }
}
</script>

<style>
.g_micro_box {
  height: 100%;
  & > div {
    height: 100%;
  }
}
</style>
